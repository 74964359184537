<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form
          ref="form"
          inline
          :model="searchData"
          label-width=""
      >
        <el-form-item label="手机号码">
          <el-input
              size="small"
              v-model="searchData.mobile"
              placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="员工">
          <el-cascader
              v-model="searchData.agent_employee_ids"
              :options="agentEmployeeTreeData"
              :show-all-levels="false"
              clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="注册时间">
          <el-date-picker
              v-model="searchData.created_at_range"
              style="width: 220px"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="-"
              size="mini"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label=" ">
          <el-button
              type="primary"
              size="mini"
              icon="el-icon-search"
              @click="getList"
          >搜索
          </el-button>
          <el-button
              type="info"
              size="mini"
              icon="el-icon-refresh"
              @click="reset"
          >重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list">
      <el-table-column
          prop="id"
          label="ID"
          width="width"
          min-width="40px"
      >
      </el-table-column>
      <el-table-column
          prop="nickname"
          label="昵称"
          width="width"
          min-width="40px"
      >
      </el-table-column>
      <el-table-column
          prop="mobile"
          label="手机号码"
          width="width"
          min-width="40px"
      >
      </el-table-column>
      <el-table-column
          prop="agent"
          label="员工"
          width="280px"
          min-width="40px"
      >
        <template slot-scope="{ row }">
          <div v-if="row?.employee?.id">
            <div>ID：{{ row?.employee?.id }}</div>
            <div>账号：{{ row?.employee?.account }}</div>
            <div>手机号码：{{ row?.employee?.mobile }}</div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
          prop="is_main"
          label="是否主账号"
          min-width="40px"
      >
        <template slot-scope="{ row }">
          <el-tag type="primary" v-if="row.is_main">是</el-tag>
          <el-tag type="danger" v-else>否</el-tag>
        </template>
      </el-table-column>

      <el-table-column
          prop="is_lock"
          label="状态"
          min-width="40px"
      >
        <template slot-scope="{ row }">
          <el-tag type="primary" v-if="! row.is_lock">正常</el-tag>
          <el-tag type="danger" v-else>锁定</el-tag>
        </template>
      </el-table-column>

      <el-table-column
          prop="expire_date"
          label="到期时间"
          width="width"
          min-width="40px"
      ></el-table-column>

      <el-table-column
          prop="give_count"
          label="赠送次数"
          width="width"
          min-width="40px"
      ></el-table-column>

      <el-table-column
          prop="created_at"
          label="创建日期"
          width="width"
          min-width="40px"
      ></el-table-column>
      <el-table-column
          label="操作"
          width="200px"
          fixed="right"
      >
        <template slot-scope="{ row }">
          <el-link style="margin-right: 10px" type="primary" :underline="false"
                   @click="$router.push({ name: 'UserDetail', params: { id: row.id } })">详情
          </el-link>
          <el-link
              style="margin-right: 10px"
              type="primary"
              @click="openDistribute(row)"
          >分配员工
          </el-link>
          <el-link
              style="margin-right: 10px"
              type="primary"
              @click="openSubmitOrder(row)"
          >手动下单
          </el-link>
          <el-link
              style="margin-right: 10px"
              type="primary"
              @click="giveUserExpireTime(row)"
          >赠送
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination
        :total="total"
        :page.sync="searchData.page"
        :limit.sync="searchData.pagesize"
        @pagination="getList"
    />

    <!--    分配用户-->
    <el-dialog
        title="分配员工"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">

      <el-form ref="form" :model="distributeEmployeeForm" label-width="120px">
        <el-form-item label="员工">
          <el-cascader
              v-model="distributeEmployeeForm.agent_employee_tmp_ids"
              :options="agentEmployeeTreeData"
              :show-all-levels="false"
              clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button @click="dialogVisible=false">取消</el-button>
          <el-button type="primary" @click="distributeEmployee()">分配</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--    修改会员过期时间-->
    <el-dialog
        title="修改会员权益"
        :visible.sync="orderDialogVisible"
        width="40%"
        :before-close="handleCloseOrder">
      <div style="margin: 20px 0px 20px 50px;">
        <el-tag type="success" style="margin-right: 10px;">{{ selectUser.mobile }}</el-tag>
        <el-tag type="success" style="margin-right: 10px;">{{ selectUser.expire_date }}</el-tag>
        <el-tag type="success">{{ selectUser.account_num }}个子账号</el-tag>
      </div>

      <el-form ref="form" :model="userEquityFormData" label-width="150px">
        <el-form-item label="类型">
          <el-radio-group v-model="userEquityFormData.order_type">
            <el-radio :label="10">购买</el-radio>
            <el-radio :label="20">赠送</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="支付方式" v-if="userEquityFormData.order_type == 10">
          <el-radio-group v-model="userEquityFormData.pay_type">
            <el-radio :label="70">对公</el-radio>
            <el-radio :label="20">微信</el-radio>
            <el-radio :label="30">支付宝</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="支付金额" v-if="userEquityFormData.order_type == 10">
          <el-input-number v-model="userEquityFormData.pay_price" :min="0" :max="10000" step="0.1"></el-input-number>
        </el-form-item>
        <el-form-item label="到期时间">
          <el-date-picker type="date" placeholder="选择日期" v-model="userEquityFormData.expire_date"
                          style="width: 100%;" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="子账号数量">
          <el-input v-model="userEquityFormData.account_num"></el-input>
        </el-form-item>
        <el-form-item label="同步修改子账号">
          <el-checkbox-group v-model="userEquityFormData.children_ids">
            <div v-for="item in selectUser?.children" :key="item.id">
              <el-checkbox :label="item.id">{{ item.merge_info }}</el-checkbox>
            </div>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="备注">
          <el-input type="textarea" v-model="userEquityFormData.remark"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleCloseOrder">取消</el-button>
          <el-button type="primary" @click="onSubmitOrder">立即下单</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {distributeEmployeeAPI, getAgentEmployeeTreeAPI, getDetailAPI, getListAPI, rechargeUserOrderAPI, giveExpireTimeAPI} from './api'
import {authBtnMixin} from '@/mixins/authBtnMixin'

export default {
  name: 'Index',
  mixins: [authBtnMixin],
  data() {
    return {
      rules: [{required: true, message: '该项不能为空', trigger: 'blur'}],
      show_detail: false,
      show_review: false,
      list: [],
      city_list: [],
      searchData: {
        page: 1,
        pagesize: 10,
        mobile: '',
        agent_name: '',
        agent_employee_ids: [],
        created_at_range: [],
      },
      total: 0,
      changeStatusData: {
        id: '',
        status: null
      },

      /*分配跟进员工*/
      agentEmployeeTreeData: [],
      dialogVisible: false,
      distributeEmployeeForm: {
        uid: 0,
        agent_employee_id: 0,
        agent_employee_tmp_ids: [],
      },

      /*会员权益-过期时间设置*/
      selectUser: {},
      orderDialogVisible: false,
      userEquityFormData: {
        uid: 0,
        pay_type: 70,
        order_type: 10,
        pay_price: 0,
        expire_date: '',
        account_num: 0,
        product_id: 0,
        children_ids: [],
        remark: '',
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    this.getList()
    this.getAgentEmployeeTree()
  },

  methods: {
    handleCloseOrder() {
      this.orderDialogVisible = false
      this.selectUser = {}
    },
    giveUserExpireTime({id}) {
      this.$confirm('是否确定赠送三天？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        giveExpireTimeAPI(id, 3).then(() => {
          this.$message.success('提交成功')
          this.orderDialogVisible = false
          this.selectUser = {}
          this.getList()
        })
      })
    },
    openSubmitOrder(row) {
      console.log(row, '@@@')
      this.distributeEmployeeForm = {
        uid: 0,
        pay_type: 70,
        order_type: 10,
        pay_price: 0,
        expire_date: '',
        account_num: 0,
        product_id: 0,
        children_ids: [],
        remark: '',
      }
      this.selectUser = row
      this.orderDialogVisible = true
      this.userEquityFormData.uid = row.id
      this.userEquityFormData.account_num = row.account_num
    },
    onSubmitOrder() {
      console.log(this.userEquityFormData, '@@@@')
      if (this.userEquityFormData.order_type == 20) { // 赠送
        this.userEquityFormData.pay_price = 0
        this.userEquityFormData.pay_type = 0
      }
      // 是否确定
      this.$confirm('是否确定提交？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        rechargeUserOrderAPI(this.userEquityFormData).then(() => {
          this.$message.success('提交成功')
          this.orderDialogVisible = false
          this.selectUser = {}
          this.getList()
        })
      })
    },
    handleClose() {

    },
    openDistribute(row) {
      this.dialogVisible = true
      this.distributeEmployeeForm = {
        uid: row.id,
        agent_employee_id: row?.employee?.id,
        agent_employee_tmp_ids: [row?.employee?.agent_id, row?.employee?.id]
      }
    },
    distributeEmployee() {
      this.distributeEmployeeForm.agent_employee_id = this.distributeEmployeeForm.agent_employee_tmp_ids[1] || 0
      distributeEmployeeAPI(this.distributeEmployeeForm).then(() => {
        this.$message.success('分配成功')
        this.dialogVisible = false
        this.getList()
      })
    },
    changeStatus() {
      changeStatusAPI(this.changeStatusData).then(() => {
        this.$message.success('提交成功')
        this.getList()
        this.show_review = false
      })
    },
    async getList() {
      // 获取get 参数
      let params = {...this.searchData}
      params.agent_employee_id = params['agent_employee_ids'][1] || undefined
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    async getAgentEmployeeTree() {
      // 获取get 参数
      this.agentEmployeeTreeData = await getAgentEmployeeTreeAPI()

    },
    // del
    async handleDel({id}) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            delAPI(id).then(() => {
              this.$message.success('删除成功')
              this.getList()
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
    },
    // 新增
    handleAdd() {
      this.show_update = true
      this.$nextTick(() => {
        this.formData = {
          name: '',
          icon: '',
          describe: ''
        }
        this.$refs.form.resetFields()
      })
    },
    // edit
    async handleEdit({id}) {
      this.show_update = true
      this.$nextTick(async () => {
        this.formData = await getDetailAPI(id)
        this.$refs.form.resetFields()
      })
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.getList()
              this.show_update = false
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.getList()
              this.show_update = false
            })
          }
        }
      })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;
      }
    }
  }

  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdfe6;

    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;
      // border-bottom: 2px solid #409eff;

      .tab-name {
        padding: 6px;
      }
    }

    .active {
      color: #409eff;
      font-weight: 600;

      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
    margin-right: 10px;
  }

  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }

  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 70px !important;
    margin: 0;
  }
}
</style>
<style lang="scss" scoped>
.icon-box /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 108px;
    height: 108px;
    line-height: 108px;
    text-align: center;
  }

  .avatar {
    width: 108px;
    height: 108px;
    display: block;
  }
}

.user-box {
  .row {
    display: flex;

    span {
      &:nth-child(1) {
        width: 72px;
        text-align: right;
      }

      &:nth-child(2) {
        text-align: left;
      }
    }
  }
}
</style>
